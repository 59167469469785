<template>
  <div id="app">
    <canvas ref="canvas" id="canvas"></canvas>
    <div
      class="countdown"
      :style="{
        width: (100 * blocked / blockDuration) + '%'
      }"
      >
    </div>
    <div class="msg" v-show="blocked > 0">{{ msg }}</div>
  </div>
</template>

<script>

import jsQR from 'jsqr'
import axios from 'axios'

const uploadEndpoint = './upload.php'

const blockDuration = 20000

const drawLineColor = '#00ff00'

var video
var ctx

export default {
  name: 'App',
  components: {
  },
  data: function () {
    return {
      msg: '',
      blocked: 0,
      blockUntil: 0,
      blockDuration,
      lastCode: false
    }
  },
  created () {
  },
  methods: {
    init () {
      var that = this
      video = document.createElement('video')
      navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } }).then(function (stream) {
        video.srcObject = stream
        video.setAttribute('playsinline', true) // required to tell iOS safari we don't want fullscreen
        video.play()
        that.$refs.canvas.height = video.videoHeight
        that.$refs.canvas.width = video.videoWidth
        requestAnimationFrame(that.tick)
      })
    },
    tick () {
      this.blocked = Math.max(0, this.blockUntil - (+new Date()))
      if (!video.readyState === video.HAVE_ENOUGH_DATA || !video.videoWidth) {
        console.log('skipping tick')
      } else {
        this.$refs.canvas.height = video.videoHeight
        this.$refs.canvas.width = video.videoWidth
        ctx.drawImage(video, 0, 0, this.$refs.canvas.width, this.$refs.canvas.height)
        if (this.lastCode && this.blocked) {
          this.drawCode(this.lastCode)
        }
        var imageData = ctx.getImageData(0, 0, this.$refs.canvas.width, this.$refs.canvas.height)
        var code = jsQR(imageData.data, imageData.width, imageData.height, {
          inversionAttempts: 'dontInvert'
        })
        if (code && code.data) { // skipping "empty" qr codes
          if (this.blocked === 0) {
            this.process(code)
            this.lastCode = code
            this.blockUntil = (+new Date()) + 10000
          } else {
            console.log('code, but not idle')
          }
        }
      }
      requestAnimationFrame(this.tick)
    },
    drawLine (begin, end, color) {
      ctx.beginPath()
      ctx.moveTo(begin.x, begin.y)
      ctx.lineTo(end.x, end.y)
      ctx.lineWidth = 4
      ctx.strokeStyle = `rgba(0, 255, 0, ${this.blocked / this.blockDuration})`
      ctx.stroke()
    },
    drawCode (code) {
      this.drawLine(code.location.topLeftCorner, code.location.topRightCorner, drawLineColor)
      this.drawLine(code.location.topRightCorner, code.location.bottomRightCorner, drawLineColor)
      this.drawLine(code.location.bottomRightCorner, code.location.bottomLeftCorner, drawLineColor)
      this.drawLine(code.location.bottomLeftCorner, code.location.topLeftCorner, drawLineColor)
    },
    process (code) {
      var that = this
      console.log(code)
      const data = new FormData()
      this.msg = 'uploading…'
      this.$refs.canvas.toBlob(function (blob) {
        data.append('blob', blob)
        data.append('code', JSON.stringify(code))
        axios.post(uploadEndpoint, data, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
          .then(res => {
            that.msg = 'uploaded ✓'
            console.info(res)
          })
          .catch(err => {
            that.msg = 'error'
            console.error(err)
            window.alert(`ERROR! ${err?.message}\n${err?.response?.data}`)
          })
      }, 'image/jpeg', 0.85)
    }
  },
  mounted () {
    ctx = this.$refs.canvas.getContext('2d')
    this.init()
  }
}
</script>

<style lang="stylus">

*
  margin 0
  padding 0
  box-sizing border-box

html
body
#app
  height 100%

body
  background black
  font-family sans-serif

#canvas
  width 100%
  height 100%
  object-fit contain
  object-position center

.countdown
  position fixed
  bottom 0
  left 0
  height 1rem
  background purple

.msg
  position fixed
  bottom 2rem
  left 1rem
  font-size 2rem
  color #00ff00
  font-weight bold

</style>
